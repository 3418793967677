import { render, staticRenderFns } from "./OneCarousel.vue?vue&type=template&id=b74e5274&scoped=true&functional=true&"
var script = {}
import style0 from "./OneCarousel.vue?vue&type=style&index=0&id=b74e5274&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/.pnpm/vue-loader@15.9.8_css-loader@4.3.0_vue-template-compiler@2.7.16_webpack@4.47.0/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  true,
  null,
  "b74e5274",
  null
  
)

export default component.exports